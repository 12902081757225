import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { useViewportSpy, useWindowScroll, useMediaQuery } from 'beautiful-react-hooks'

import directMail from './images/DirectMail.png'
import email from './images/Email.png'
import phonathon from './images/Phonathon.png'
import text from './images/Text.png'
import voicemail from './images/Voicemail.png'

import styles from './Engaging.module.scss'

const Engaging = () => {
    const ref = useRef();
    // const visible = useViewportSpy(ref)
    const [scrollY, setScrollY] = useState((typeof window !== 'undefined' && window.scrollY) || 0)
    const isMobile = useMediaQuery('(max-width: 767px)')

    useWindowScroll((event) => {
        setScrollY((typeof window !== 'undefined' && window.scrollY) || 0)
    })

    return (
        <section className={styles.engaging}>
            <div className={styles.innerSection}>
                <div className={styles.title}>
                    We specialize in engaging audiences using <br /> direct marketing channels.
                </div>

                <div className={styles.channels}>
                    <div className={styles.channel}>
                        <span style={{
                            backgroundImage: `url(${phonathon})`,
                            width: '30px',
                            height: '30px'
                        }} />
                        <div>Phonathon</div>
                    </div>
                    <div className={styles.channel}>
                        <span style={{
                            backgroundImage: `url(${directMail})`,
                            width: '32px',
                            height: '32px'
                        }} />
                        <div>Direct mail</div>
                    </div>
                    <div className={styles.channel}>
                        <span style={{
                            backgroundImage: `url(${email})`,
                            width: '31px',
                            height: '24px'
                        }} />
                        <div>Email</div>
                    </div>
                    <div className={styles.channel}>
                        <span style={{
                            backgroundImage: `url(${text})`,
                            width: '28px',
                            height: '28px'
                        }} />
                        <div>Text messaging</div>
                    </div>
                    <div className={cn(styles.channel, styles.channelVoice)}>
                        <span style={{
                            backgroundImage: `url(${voicemail})`,
                            width: '27px',
                            height: '23px'
                        }} />
                        <div>Ringless voicemail</div>
                    </div>
                </div>
            </div>

            <div className={styles.greenArea}>
                <div className={styles.greenAreaContainer}>

                    <div ref={ref} className={styles.visibleArea} />

                    <span className={cn(styles.imgMask, {
                            [styles.visible]: isMobile
                        })}
                        style={{
                            left: isMobile ? null : scrollY > 1000 ? '6.7em' : `${(scrollY/150)}em`
                        }}
                    />

                    <div>
                        <h2 className={cn(styles.greenAreaTitle, {
                            [styles.visible]: isMobile
                        })} style={{
                            right: isMobile ? null : scrollY > 1000 ? '1.78em' : `${(scrollY/565)}em`
                        }}>
                            Versatile, agile and effective campaign designs.
                        </h2>

                        <p className={cn(styles.information, {
                            [styles.visible]: isMobile
                        })} style={{
                            right: isMobile ? null : scrollY > 1000 ? '2em' : `${(scrollY/500)}em`
                        }}>
                            Delivering industry leading design and online experiences helps us maximize donor conversion and the performance of your campaign.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Engaging
