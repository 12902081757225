import React, { useRef, useState } from 'react'
import { SwiperSlide } from 'swiper/react' // Swiper
import { useMediaQuery } from 'beautiful-react-hooks'

// import cn from 'classnames'
// import IconPrev from './IconPrev'
// import IconNext from './IconNext'

import stars from './images/stars.png'

import styles from './NonProfitTestimonial.module.scss'

const SLIDES = [
    {
        message: "We have partnered with Shift Marketing on several campaigns, and have been highly impressed with both the service and the results. Within 2-3 weeks of delivery, our most recent appeal produced more than five times what our investment was, and - as always - Shift made the entire process incredibly easy for our team!",
        stars: stars,
        position: ' Director of Development & Community Engagement, American Rescue Workers',
        name: "Valerie Fessler, ",
        logo: null,
        // logoAlt: 'Volkswagen',
        // logoWidth: 40,
        // logoHeight: 40
    },
]

const NonProfitTestimonial = () => {
    const swiperRef = useRef(null)
    const [onReachEnd, setReachEnd] = useState(false)
    const [onBeginning, setBeginning] = useState(false)
    const isMobile = useMediaQuery('(max-width: 992px)')

    const goNext = () => {
        if (!onReachEnd && swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext()
        }
    }

    const goPrev = () => {
        if (!onBeginning && swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev()
        }
    }

    return (
        <section className={styles.NonProfitTestimonial}>
            <h2 className={styles.title}>Don't just take our word for it!</h2>
                {/* <Swiper
                    spaceBetween={30}
                    // centeredSlidesBounds
                    centeredSlides
                    slidesPerView={1}
                    initialSlide={Math.ceil(SLIDES.length / 2)}
                    breakpoints={{
                        320: {
                            slidesPerView: 1.5,
                            spaceBetween: 22
                        },
                        768: {
                            slidesPerView: 2.5,
                            spaceBetween: 20,
                        },
                        1200: {
                            slidesPerView: 2.9,
                            spaceBetween: 30
                        },
                        1440: {
                            slidesPerView: 4,
                            spaceBetween: 30
                        }
                    }}
                    onProgress={progress => {
                        setBeginning(progress.isBeginning)
                        setReachEnd(progress.isEnd)
                    }}
                    ref={swiperRef}
                > */}
                       {SLIDES.map(({
                           message,
                           stars,
                           position,
                           name,
                        //    logo,
                        //    logoAlt,
                        //    logoHeight,
                        //    logoWidth
                       }, i) => (
                            <SwiperSlide style={{ width: '100%', margin: '0 auto' }}>
                                <div className={styles.testimonialContainer} key={i}>
                                    <div
                                        className={styles.stars}
                                        style={{
                                            backgroundImage: `url(${stars})`,
                                        }}
                                    />

                                  <div className={styles.testimonial}>
                                        <p className={styles.testimonialP}>
                                            {message}
                                        </p>
                                  </div>
                                  <div className={styles.testimonialMeta}>
                                        <strong>{name}</strong>&nbsp;{position}
                                    </div>
                                </div>
                            </SwiperSlide>
                       ))}
                {/* </Swiper> */}


             {/* <span className={styles.swiperNavigationContainer}>
                 <div className={cn(styles.btn, styles.btnLeft, {
                     [styles.disable]: onBeginning,
                 })} onClick={goPrev}>
                     <IconPrev backgroundColor={'#EC1B2E'} />
                 </div>

                 <div
                     className={cn(styles.btn, styles.btnNext, {
                         [styles.disable]: onReachEnd,
                     })}
                     onClick={goNext}
                 >
                     <IconNext backgroundColor={'#EC1B2E'} />
                 </div>
             </span> */}
        </section>
    )
}

const formatMessage = (message, slice) => {
    if (slice) {
        return `“${message.slice(0, 200)}${message.length >= 200 ? '...' : ''}”`
    }

    return `“${message}”`
}

export default NonProfitTestimonial
