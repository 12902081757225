import React, { useState, useRef } from 'react'
// import cn from 'classnames'
import MediaQuery from 'react-responsive'

import { Store } from 'store'
import { setModalOpen } from "layouts/DefaultLayout"
import { useWindowScroll, useMediaQuery, useViewportSpy } from 'beautiful-react-hooks'

import styles from './NonProfitHero.module.scss'

const NonProfitHero = () => {
    const ref = useRef()
    const {dispatch} = React.useContext(Store)
    const [scrollY, setScrollY] = useState((typeof window !== 'undefined' && window.scrollY) || 0)
    const isMobile = useMediaQuery('(max-width: 992px)')
    const visible = useViewportSpy(ref)

    useWindowScroll(event => {
        setScrollY((typeof window !== 'undefined' && window.scrollY) || 0)
    })

    return (
        <section className={styles.hero} ref={ref} style={isMobile || !visible ? null : {
            backgroundPositionY: -1 * scrollY/12,
            transition: 'all 0.05s linear'
        }}>
            <div className={styles.innerSection}>
                <div className={styles.left}>
                    <div className={styles.leftInner}>
                        <MediaQuery minWidth={769}>
                            <h1 className={styles.title}>
                                Elevating non-profit <br /> fundraising and engagement.
                            </h1>
                            <p className={styles.subtitle}>
                                Bring your non-profit direct marketing to the 21st century and maximize results with Shift Marketing.
                            </p>
                            <div className={styles.btnContainer}>
                                <button onClick={() => setModalOpen(dispatch, 'nonprofit')} className="sh-button-primary __large" style={{ width: '210px' }}>Book a demo</button>
                            </div>
                        </MediaQuery>
                        <MediaQuery maxWidth={768}>
                            <h1 className={styles.title}>
                                Elevating non-profit <br /> fundraising and <br /> engagement.
                            </h1>
                        </MediaQuery>
                    </div>
                </div>

                <div className={styles.right} />
                <MediaQuery maxWidth={768}>
                    <div className={styles.btnContainer}>
                        <button onClick={() => setModalOpen(dispatch, 'nonprofit')} className="sh-button-primary __large" style={{ width: '210px' }}>Book a demo</button>
                    </div>
                </MediaQuery>
            </div>
        </section>
    );
};

export default NonProfitHero
