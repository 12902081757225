import React from 'react'
import cn from 'classnames'
import { setModalOpen } from "layouts/DefaultLayout"

import { Store } from 'store'

import styles from './Phonathon.module.scss'

const Phonathon = () => {
    const { dispatch } = React.useContext(Store)
    return (
        <div className={styles.Phonathon}>
            <div className={styles.innerContainer}>
                <div className={styles.whiteBox}>
                    <h2 className={styles.title}>Phonathon</h2>
                    <p className={styles.message}>
                        Work with our expert phonathon team and build a strategy to engage
                        your audience over the phone. Phonathon remains an effective component
                        of a comprehensive outreach strategy.
                    </p>
                    <a
                        onClick={() => {
                            setModalOpen(dispatch, 'non_profit_phonathon_product_sheet')
                        }}
                        className={cn("sh-button-primary __large", styles.btn)}
                        href="#"
                    >
                        Download product sheets
                    </a>
                </div>
            </div>
        </div>
    )
}

// Phonathon.propTypes = {}

export default Phonathon
