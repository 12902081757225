import React, { useRef, useState } from 'react'
import { Store } from 'store'

import {
    useViewportSpy,
    useWindowScroll,
    useMediaQuery,
} from 'beautiful-react-hooks'

import styles from './AnnualGiving.module.scss'

import { setModalOpen } from 'layouts/DefaultLayout'

const AnnualGiving = props => {
    const ref = useRef()
    const refShiftDashboard = useRef()
    const { dispatch } = React.useContext(Store)
    const visible = useViewportSpy(ref)
    const visibleShiftDashboard = useViewportSpy(refShiftDashboard)
    const [scrollY, setScrollY] = useState(
        (typeof window !== 'undefined' && window.scrollY) || 0
    )
    const isMobile = useMediaQuery('(max-width: 767px)')

    useWindowScroll(event => {
        const value =
            ((typeof window !== 'undefined' && window.scrollY) || 0) / 200
        if (!isMobile) {
            setScrollY(value)
        }
    })

    return (
        <div className={styles.annualGiving}>
            <div className={styles.container}>
                <div className={styles.lefSide} />
                <div
                    className={styles.rightSide}
                    style={
                        isMobile
                            ? null
                            : {
                                  left: visibleShiftDashboard
                                      ? '0em'
                                      : '-0.6em',
                                  position: 'relative',
                              }
                    }
                >
                    <h2 className={styles.annualGivingTitle}>Giving Pages</h2>

                    <p className={styles.annualGivingP}>
                        With online giving on the rise, building an online
                        experience that drives engagement and donor
                        generosity is critical to success. Our simple, beautiful
                        and mobile friendly giving pages give your organization
                        the tools it needs to succeed online.
                    </p>

                    <a
                        onClick={e => {
                            e.preventDefault()
                            setModalOpen(dispatch, 'non_profit_product_sheet_giving_pages')
                        }}
                        className="sh-button-primary __large"
                        href="#"
                    >
                        Download product sheet
                    </a>

                    <div className={styles.doubleDonation}>
                        <strong>
                            Shift Marketing Selects Double the Donation<br />
                            as our Matching Gift Software Provider
                        </strong>

                        <div className={styles.doubleDonationImg} />
                    </div>
                </div>
            </div>

            <span
                className={styles.greenRectangle}
                style={
                    isMobile
                        ? null
                        : {
                              left: '-19vw',
                              transform: visible ? `translateX(20)vw` :`translateX(${scrollY}vw)`
                          }
                }
            />
            <span
                className={styles.yellowRectangle}
                style={
                    isMobile
                        ? null
                        : {
                            //   left: '0vw',
                              transform: visible ? `translateX(20)vw` :`translateX(${scrollY}vw)`
                          }
                }
            />
            <span
                className={styles.shiftDashboard}
                ref={refShiftDashboard}
            />
            <div className={styles.visibleArea} ref={ref} />
        </div>
    )
}

export default AnnualGiving
