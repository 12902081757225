import React from 'react'

import Layout from 'layouts/DefaultLayout'
import SEO from 'components/SEO'
import NonProfitHero from '../page-components/NonProfit/NonProfitHero/NonProfitHero'
import NonProfitBrands from '../page-components/NonProfit/NonProfitBrands/NonProfitBrands'
import Engaging from '../page-components/NonProfit/Engaging/Engaging'
import ShiftDemo from '../page-components/NonProfit/ShiftDemo/ShiftDemo'
import AnnualGiving from '../page-components/NonProfit/AnnualGiving/AnnualGiving'
import NonProfitTestimonial from '../page-components/NonProfit/NonProfitTestimonial/NonProfitTestimonial'
import FooterCTA from 'components/FooterCTA'
import Separator from '../components/Separator/Separator'
import Phonathon from '../page-components/NonProfit/Phonathon/Phonathon'

import thumbnail from 'images/non-profit-thumbnail.jpg'

export default () => {
    return (
        <Layout modalType="general" logoText="Marketing">
            <SEO
                title='Non-Profit | Shift Marketing'
                description='Donor retention direct marketing for non-profit fundraising.'
                image={`https://shiftmarketing.io${thumbnail}`}
                canonical={'https://shiftmarketing.io/non-profit'}
                ogUrl={'https://shiftmarketing.io/non-profit'}
                siteName={'Non-Profit | Shift Marketing'}
            />
            <NonProfitHero/>
            <NonProfitBrands/>
            <Separator container style={{ marginTop: 80, marginBottom: 70 }}/>
            <Engaging/>
            <ShiftDemo/>
            <AnnualGiving/>
            <Phonathon/>
            <NonProfitTestimonial/>
            <FooterCTA modalType="nonprofit"/>
        </Layout>
    )
}
